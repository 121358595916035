import { initializeApp } from "firebase/app";
import {getFirestore} from '@firebase/firestore'
import {getFunctions} from 'firebase/functions'
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyB_vbyySHa38I9m4nhd1bgwTIcrQoTmkJE",
    authDomain: "wardrobebuilder-f776e.firebaseapp.com",
    projectId: "wardrobebuilder-f776e",
    storageBucket: "wardrobebuilder-f776e.appspot.com",
    messagingSenderId: "183782410945",
    appId: "1:183782410945:web:a452da375fd1ed7bb76240",
    measurementId: "G-80W1VG35ZL"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const functions = getFunctions(app);

export const storage = getStorage(app);


